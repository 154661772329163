import Link from 'next/link';

//Basic Country Link Layout, that defines a route, and a title, and returns a JSX element of a Link with a Title
const BasicCountryLink = ({ title, country, text1, text2, photo, vaccines, map_lat, map_long, inline, className }) => {
	const onlyCountry = country.split('.')[1].toLowerCase();
	const data = {
		map_lat: map_lat,
		map_long: map_long,
		photo: photo,
		vaccines: vaccines,
		country: `countries.${country}`,
		text1: `countries.${text1}`,
		text2: `countries.${text2}`,
		className: className || `country_list clickable country_links ${inline || ''}`
	};

	return (
		<Link
			passHref
			as={`/country/${onlyCountry}`}
			href={{ pathname: '/country/[id]', query: { data: JSON.stringify(data) } }}
		>
			<a className={data.className}>{title}</a>
		</Link>
	);
};

export default BasicCountryLink;
