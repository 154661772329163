import React from 'react';
import Head from 'next/head';
import TopInfo from '@/components/shared/TopInfo';
import NavBar from '@/components/shared/NavBar';
import Footer from '@/components/shared/Footer';
import { setTranslations, setDefaultLanguage } from 'react-switch-lang';
import HandleLanguageLoading from '@/Handlers/HandleLanguageLoading';
import en from '@/languages/en.json';
import da from '@/languages/da.json';
import sv from '@/languages/sv.json';

// Do this two lines only when setting up the application
setTranslations({ en, da, sv });
setDefaultLanguage('da');

//Retrieving user, loading, className, and children from porps
const BaseLayout = ({ className, children, title, description, keywords, canonical }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Meta Tags */}
			<main className={`${className}`}>
				<Head>
					{/* SEO Tags */}
					<title>{`VACCIN DK - ${title}`}</title>
					<meta name="description" content={description} />
					<meta name="keywords" content={keywords} />
					{/* OG Tags */}
					<meta property="og:locale" content="da_DK" />
					<meta property="og:locale:alternate" content="en_DK" />
					<meta property="og:locale:alternate" content="sv_DK" />
					<meta property="og:title" content={`VACCIN DK - ${title}`} />
					<meta property="og:type" content="website" />
					<meta property="og:description" content={description} />
					<meta property="og:image" content="https://vaccin.dk/images/consultation.webp" />
					<meta property="og:url" content="https://vaccin.dk/" />
					<meta property="og:site_name" content="VACCIN DK" />
					{/* Twitter Tags */}
					<meta name="twitter:card" content="summary" />
					<meta property="twitter:url" content="https://vaccin.dk/" />
					<meta property="twitter:title" content={`VACCIN DK - ${title}`} />
					<meta property="twitter:description" content={description} />
					<meta property="twitter:image" content="https://vaccin.dk/images/consultation.webp" />
					{/* ViewPort Tags */}
					<meta name="viewport" content="width=device-width, user-scalable=no, initial-scale=1" />
					<meta name="HandheldFriendly" content="true" />
					<link rel="alternate" href="https://vaccin.dk/da" hrefLang="da" />
					<link rel="alternate" href="https://vaccin.dk/en" hrefLang="en" />
					<link rel="alternate" href="https://vaccin.dk/sv" hrefLang="sv" />
					{canonical && <link rel="canonical" href={`https://vaccin.dk${canonical}`} />}
					<link rel="home" href="https://vaccin.dk/" />
					<meta name="robots" content="index,follow" />
					<meta name="parsely-link" content="https://vaccin.dk/" />
					<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
					{/* Global site tag (gtag.js) - Google Ads: 432741652 */}
					<script async src="https://www.googletagmanager.com/gtag/js?id=AW-432741652" />
					<script
						dangerouslySetInnerHTML={{
							__html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-432741652');`
						}}
					/>
				</Head>
				{/* Base NavBar - Shared component */}
				<HandleLanguageLoading />
				<TopInfo />
				<NavBar />
				<section className="wrapper">{children}</section>
				<Footer />
			</main>
			<script
				dangerouslySetInnerHTML={{
					__html: `document.addEventListener('click', function(e){if(e.target.matches('a, a *') && e.target.innerText.toLowerCase().indexOf('tidsbestilling') != -1){gtag('event', 'conversion', {'send_to': 'AW-432741652/qm3WCIzs5v4BEJS6rM4B'});}}, true);`
				}}
			/>
		</React.Fragment>
	);
};

export default BaseLayout;
