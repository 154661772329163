import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Booking Button
const BookingButton = ({ booking }) => {
	return (
		<a		 
			rel="external nofollow noopener"
			target="_blank"
			className="nav-link port-navbar-link active open_booking-iframe-navbutton clickable"
		>
			<FontAwesomeIcon icon={[ 'fas', 'calendar-check' ]} />&nbsp;{booking}
		</a>
	);
};

export default BookingButton;
