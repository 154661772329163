import React from 'react';
import BasePage from '@/components/BasePage';
import Link from 'next/link';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'react-switch-lang';

const TopInfo = ({ t }) => {
	return (
		//React.Fragment is used instead of <div> or <> to hide it from source
		<React.Fragment>
			{/* Base Page - Shared component with Container */}
			<BasePage id="topinfo-page" className="topinfo-page" role="banner">
				<header className="pt-3">
					<Row itemScope itemType="https://schema.org/LocalBusiness">
						{/* Company Logo */}
						<Col xs="2" md="1" itemScope itemType="http://schema.org/Attorney">
							<Link href="/">
								<a itemProp="url" title={t('topinfo.logo')}>
									<img
										itemProp="logo"
										className="company_logo"
										src="/icons/logo.webp"
										alt={t('topinfo.logo')}
										title={t('topinfo.logo')}
									/>
								</a>
							</Link>
						</Col>
						{/* Company Header and information */}
						<Col
							title={t('topinfo.logo')}
							className="topinfo_logo-header-company text-uppercase"
							xs="8"
							md="3"
						>
							<Link href="/">
								<a itemProp="url" title="VACCIN DK ">
									<p itemProp="name">
										<span className="font-weight-bold colored title">VACCIN DK</span>
										<span className="d-block" itemProp="description">
											{t('topinfo.company')}
										</span>
									</p>
								</a>
							</Link>
						</Col>
						{/* Company Location */}
						<Col className="topinfo_logo-header-location d-none d-md-inline text-uppercase" md="5" lg="4">
							<a
								title={`${t('topinfo.location')}: Amager Landevej 31, 2770 Kastrup`}
								rel="external nofollow noopener"
								target="_blank"
								href="https://www.google.com/maps/place/Amager+Landevej+31,+2770+Kastrup/@55.6375594,12.6167232,17z/data=!3m1!4b1!4m5!3m4!1s0x4653ab59f87606ef:0x29e0296915c20f9a!8m2!3d55.6375594!4d12.6189119"
							>
								<FontAwesomeIcon icon={[ 'fas', 'map-marker-alt' ]} className="fai_map-marker" />
								{t('topinfo.location')}
								<span className="title d-block">
									<span itemProp="streetAddress">Amager Landevej 31</span>,<span itemProp="postalCode"> 2770</span>
								</span>
							</a>
						</Col>
						{/* Company opening hours */}
						<Col
							title={t('topinfo.hours')}
							className="topinfo_logo-header-time d-none d-lg-inline text-uppercase"
							md="1" lg="2"
						>
							<FontAwesomeIcon icon={[ 'fas', 'clock' ]} className="fai_clock" />
							<p>
								{t('topinfo.open')}
								<span className="title d-block">
									<time itemProp="openingHours" dateTime="Mon to Fri 08:00-16:00">
										08.00 - 16.00
									</time>
								</span>
							</p>
						</Col>
						{/* Company phone number */}
						<Col className="topinfo_logo-header-phone d-none d-md-inline text-uppercase" md="3" lg="2">
							<a title={`${t('topinfo.telephone')} +4554558965`} href="tel:+4554558965">
								<FontAwesomeIcon icon={[ 'fas', 'phone' ]} className="fai_phone" />
								<span>{t('topinfo.call')}</span>
								<span className="title d-block" itemProp="telephone">
									(+45) 54558965
								</span>
							</a>
						</Col>
					</Row>
				</header>
			</BasePage>
		</React.Fragment>
	);
};
export default translate(TopInfo);
